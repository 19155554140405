import React from "react";
import {
  AppBar,
} from "@material-ui/core";

import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import HeaderToolbarPage from "./header-toolbar-page";
import HeaderToolbarModal from "./header-toolbar-modal";

export default function Header({
  title,
  icon,
  menuDisable
}) {
  return (
    <>
      <AppBar position="fixed">
        <ModalRoutingContext.Consumer>
          {({ modal }) => (
            <div>
              {modal ? (
                <HeaderToolbarModal title={title} icon={icon} />
              ) : (
                <HeaderToolbarPage menuDisable={menuDisable} />
              )}
            </div>
          )}
        </ModalRoutingContext.Consumer>
      </AppBar>
      <div style={{height: 62}}></div>
    </>
  );
}
