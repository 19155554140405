import React from "react";
import {
  Toolbar,
  Box,
} from "@material-ui/core";

import { ModalRoutingContext } from 'gatsby-plugin-modal-routing';
import LinkGatsby from 'gatsby-link';
import CloseIcon from '@material-ui/icons/Close';

const isSSR = typeof window === "undefined";

export default function HeaderToolbarModal({
  title,
  icon
}) {
  return (
    <Toolbar>
      <Box display="flex" flexGrow={1}>
        {icon} <span style={{ fontSize: 18, marginLeft: icon ? 10 : 0 }}>{title}</span>
      </Box>

      <ModalRoutingContext.Consumer>
        {({ closeTo }) => (
          <>
            {isSSR ? <></> : <LinkGatsby to={closeTo}><CloseIcon style={{ color: '#fff' }} /></LinkGatsby>}
          </>
        )}
      </ModalRoutingContext.Consumer>
    </Toolbar>
  );
}
