import React, { useEffect, useState } from "react";
import {
  Toolbar,
  Typography,
  Box,
  Hidden,
  Menu,
  MenuItem,
  Backdrop,
  CircularProgress,
  makeStyles,
  Divider,
} from "@material-ui/core";

import AccountCircle from '@material-ui/icons/AccountCircle';
import { getCurrentUser, hasCurrentUser, logout } from "../../services/auth";
import { navigate } from "gatsby-link";
import LinkGatsby from 'gatsby-link';
import api from "../../services/api";
import Swal from 'sweetalert2';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function HeaderToolbarPage({
  menuDisable = false
}) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [user, setUser] = useState(undefined);
  const [openGoingOut, setOpenGoingOut] = useState(false);
  const [menu, setMenu] = useState([]);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const profileAction = () => {
    closeMenu();
    navigate('/profile', {
      state: {
        modal: true
      }
    });
  };

  const licenseAction = () => {
    closeMenu();
    navigate('/license?id=referrer', {
      state: {
        modal: true
      }
    });
  };

  const statementAction = () => {
    closeMenu();
    navigate('/statement');
  };

  const statementPointsAction = () => {
    closeMenu();
    navigate('/statement/points');
  };


  const organizationAction = () => {
    closeMenu();
    navigate('/organization');
  };

  const userAction = () => {
    closeMenu();
    navigate('/user');
  };

  const optionsAction = () => {
    closeMenu();
    navigate('/config/option');
  };

  const workflowAction = () => {
    closeMenu();
    navigate('/workflow/board');
  };


  const workflowAgenda = () => {
    closeMenu();
    navigate('/workflow/agenda');
  };

  const workflowActivities = () => {
    closeMenu();
    navigate('/workflow/activities');
  };

  const dashboardAction = () => {
    closeMenu();
    navigate('/dashboard');
  };

  const execLocalLogout = () => {
    logout();
    navigate('/');
  };

  const logoutAction = async () => {
    setOpenGoingOut(true);

    try {
      await api.post('/auth/logout');
      execLocalLogout();
    } catch (error) {
      if (error.response.status === 401) {
        execLocalLogout();
      } else {
        Swal.fire({
          title: 'Erro desconhecido ao tentar sair',
          icon: 'error',
          confirmButtonColor: '#000',
        });
      }
    }

    setOpenGoingOut(false);
    closeMenu();
  };

  useEffect(() => {
    if (!user && hasCurrentUser()) {
      setUser(getCurrentUser());
    }

    if (!user) return;

    const group1 = [
      { title: 'Processos', action: workflowAction, permission: 'process.view' },
      { title: 'Agenda', action: workflowAgenda, permission: 'agenda.view' },
      { title: 'Atividades', action: workflowActivities, permission: 'activities.view' },
      { title: 'Dashboard', action: dashboardAction, permission: 'dashboard.view' },
    ];

    const group2 = [
      { title: 'Comissões', action: statementAction, permission: 'statement.write' },
      { title: 'Sistema de pontuação', action: statementPointsAction, permission: 'statement.write' },
      { title: 'Organizações', action: organizationAction, permission: 'organization.write' },
      { title: 'Usuários', action: userAction, permission: 'user.write' },
      { title: 'Opções', action: optionsAction, permission: 'configuration.write' },
    ];

    const menu = [];
    menu.push(group1.filter(g => user?.permissions?.includes(g.permission)));
    menu.push(group2.filter(g => user?.permissions?.includes(g.permission)));

    setMenu(menu);
  }, [user]);

  const isSSR = typeof window === "undefined";

  return (
    <>
      <Toolbar>
        <Box display="flex" flexGrow={1}>
          {isSSR ? <></> : (
            <LinkGatsby to="/">
              <img src={`/images/logo_criare.png`} alt="Criare Da Vinci" height={35} />
            </LinkGatsby>
          )}
        </Box>

        {!menuDisable && (
          <>
            <AccountCircle fontSize="large" style={{ marginRight: 10, cursor: 'pointer' }} onClick={openMenu} />
            {user && (
              <Hidden xsDown>
                <div onClick={openMenu} style={{ cursor: 'pointer' }} >
                  <Typography variant="subtitle2" style={{ lineHeight: 1 }}>{user.name}</Typography>
                  <Typography variant="caption" style={{ fontSize: 9 }}>{user.organization?.name}</Typography>
                </div>
              </Hidden>
            )}
            <Menu
              id="header-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={closeMenu}
            >
              {menu.map(groups => {
                if (groups.length === 0) return <></>;

                return (
                  <>
                    {groups.map(group => {
                      return (
                        <MenuItem onClick={group.action}>{group.title}</MenuItem>
                      );
                    })}
                    <Divider />
                  </>
                );
              })}

              <MenuItem onClick={profileAction}>Meus dados</MenuItem>
              <MenuItem onClick={licenseAction}>Regulamento afiliados</MenuItem>
              <MenuItem onClick={logoutAction}>Sair</MenuItem>
            </Menu>
          </>
        )}
      </Toolbar>
      <Backdrop className={classes.backdrop} open={openGoingOut}>
        <CircularProgress color="inherit" /> Saindo...
      </Backdrop>
    </>
  );
}
